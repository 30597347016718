<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div id="article">
      <div class="page">
        <div class="content">
          <div class="title-warp">
            <div class="title">
              {{article.title}}
            </div>
          </div>
          <div class="time-warp">
            <div class="time">
              <i class="iconfont icon-time"></i>
              <span>{{article.time}}</span>
            </div>
            <div class="yuedu">
              <i class="iconfont icon-attention"></i>
              <span>{{article.views}}</span>
            </div>
          </div>
          <div class="main" v-html="article.content"></div>
          <div class="paging">
            <div class="left" @click="changeArticle(article.up_article)" v-if="article.up_article">
              <i class="iconfont icon-back"></i>
              <div class="pre">{{article.up_article.title}}</div>
            </div>
            <div class="left" v-if="!article.up_article">
              <div class="pre"></div>
            </div>
            <div class="iconfont icon-apps" @click="backList(article)"></div>
            <div class="right" @click="changeArticle(article.next_article)" v-if="article.next_article">
              <div class="next">{{article.next_article.title}}</div>
              <i class="iconfont icon-right"></i>
            </div>
            <div class="right" v-if="!article.next_article">
              <div class="next"></div>
            </div>
          </div>
        </div>
        <div class="ad">
          <img src="../../assets/images/ad1.jpg" alt="">
          <div class="title">为您推荐</div>
          <img src="../../assets/images/ad2.jpg" alt="">
        </div>
      </div>
    </div>
	<Footer2022></Footer2022>
  </div>

</template>

<script>
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
import Banner from '@/components/banner.vue'
import utils from '@/common/utils'
import { mapState } from 'vuex'

export default {
  name:"newsDetail",
  data () {
    return {
      // bannerList: [
      //   {
      //     image: require("../../assets/images/aijia-banner.jpg"),
      //     title: "住小院 享生活"
      //   },
      //   {
      //     image: require("../../assets/images/aijia-banner.jpg"),
      //     title: "住小院 享生活",
      //   },
      // ],
      article:{}
    }
  },
  components: {
    Banner,
    Header2022,
    Footer2022,
  },
  computed: {
    ...mapState(['newsApi']),
  },
  watch: {
    '$route':{
      handler: function (to) {
        if(to.name == "newsDetail" && to.params.id){
          this.getData();
        }
      },
      immediate: true
    }
  },
  methods: {
    getData: function () {
      let that = this;

      that.$axios.post(that.newsApi + 'cms/articleDetail', {
        'article_id': that.$route.params.id
      })
        .then(res => {
          if (res.data.code == 1) {
            that.article = {
              ...res.data.data,
              time: utils.date("Y-m-d H:i:s",res.data.data.createtime)
            }
			document.title=that.article.title;
          }
        })
    },
    changeArticle:utils.debounce(function(e){
      this.$router.push({ name: 'newsDetail' , params:{ id : e.id ,paramsName: this.$route.params.paramsName}});
    },200,true),
    backList:utils.debounce(function(e){
      this.$router.push({ name: "newsList", params: { paramsName: this.$route.params.paramsName } });
    },200,true),
  },
}
</script>

<style lang="scss">
	#article {
	  background-color: #F4F4F4;
	  padding: 64px 0 50px;
	}
	#article .page {
	  display: flex;
	  justify-content: space-between;
	  align-items: flex-start;
	  margin-top: 40px;
	}
	
	#article .content {
	  width: 814px;
	  background-color: #fff;
	  padding: 50px 36px;
	  box-sizing: border-box;
	  overflow: hidden;
	}
	
	#article .title-warp {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	}
	
	#article .title-warp .title {
	  font-size: 26px;
	  line-height: 40px;
	  width: 90%;
	}
	#article .time-warp {
	  display: flex;
	  font-size: 14px;
	  color: #666666;
	  margin-top: 23px;
	}
	
	#article .time-warp .time {
	  margin-right: 25px;
	}
	#article .time-warp .time .iconfont{
	  margin-right: 5px;
	}
	#article .main {
	  margin-top: 30px;
	  line-height: 30px;
	  overflow: hidden;
	}
	#article .main img{
	  max-width: 100%;
	  display: block;
	  width: 100%;
	}
	
	#article .time-warp .yuedu .iconfont{
	  margin-right: 5px;
	}
	#article .paging {
	  display: flex;
	  margin-top: 80px;
	  justify-content: center;
	  border-top: 1px solid #EDEDED;
	  border-bottom: 1px solid #EDEDED;
	  height: 64px;
	  align-items: center;
	}
	
	#article .paging .left, .article .paging .right {
	  display: flex;
	  height: 64px;
	  line-height: 64px;
	  width: 100%;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
	
	#article .paging .left .pre {
	  width: 100%;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
	
	#article .paging .right .next {
	  width: 100%;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
	
	#article .paging .left .iconfont {
	  margin-right: auto;
	}
	
	#article .paging .right .iconfont {
	  margin-left: auto;
	}
	
	#article .paging .icon-apps {
	  height: 64px;
	  width: 64px;
	  line-height: 64px;
	  text-align: center;
	  font-size: 28px;
	  margin: 0 38px;
	}
	
	#article .ad {
	  width: 321px;
	  background-color: #fff;
	  padding: 20px;
	}
	
	#article .ad .title {
	  padding: 8px 0;
	}
	
	#article .ad img {
	  margin-bottom: 8px;
	}
</style>